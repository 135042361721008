@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans';
}

body {
  background-color: rgb(201, 201, 201);
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
span {
    margin-left: 16px 18px 0px 18px;
}
.navbar {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    margin-top: 4px;
    padding-top: 9px;
    align-items: center;
    /* border: 1px solid black; */
    border-radius: 50%;
}

.menu-bars {
    margin: 45px 20px;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #fff;
    width: 130px;
    height: 275px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -90%;
    transition: 1500ms;
    transition-delay: 0ms;
    border-radius: 6px 6px 6px 6px;
    box-shadow: .5px .5px 5px lightslategray;
    margin-top: 30px;
    overflow: hidden;
}

.nav-menu.active {
    right: 35px;
    transition: 500ms;
}

.nav-text {
    display: flex;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #75be9a;
    transition: 500ms;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 20px;

}

.toggler {
    padding: 12px;
    background-color: white;
    border-radius: 50%;
    padding: 10px 12px 2px 12px;
    box-shadow: .5px .5px 5px rgb(121, 121, 121);
}

.toggler:active {
    box-shadow: .5px .5px 3px #797979;
    background-color: #fafafa;
}

.close-icon {
    font-size: 20px;
}


@media (max-width: 768px) {
    .toggler {
        background-color: white;
        border-radius: 50%;
        padding: 10px 12px 2px 12px;
        box-shadow: .5px .5px 5px rgb(121, 121, 121);
    }

    .navbar {
        border: none;
    }
  }
h4 {
    font-size: 25px;
  }
  
  .name,
  .position {
    padding-bottom: 4px;
  }
  
  .main-header {
    background-color: white;
    height: 175px;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
  }
  
  .primary-container {
    max-width: 1145px;
    margin: auto;
    height: inherit;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .profile-badge {
    padding: 24px 0 0 30px;
    display: inline-block;
  }
  
  .profile-image-container {
    float: left;
  }
  
  .profile-content {
    width: 400px;
    display: inline-block;
    padding-left: 30px;
    padding-top: 18px;
  }
  
  .profile-image {
    max-width: 125px;
  }
  
  .main-navigation {
    float: right;
    padding-right: 55px;
    padding-top: 55px;
  }
  
  .to-inline {
    display: inline;
  }


  @media (max-width: 768px) {
    .main-navigation {
      position: fixed;
      bottom: 30px;
      right: 30px;
      padding-right: 0;
      padding-top: 0;
    }
  }

  @media (max-width: 720px) {
    .main-header {
      display: block;
      height: 260px;
      margin: auto;
    }
    .profile-image-container {
      display: block;
      float: none;
      margin: auto;
      max-width: 100%;
      justify-content: center;
    }
    .profile-content {
      display: block;
      float: none;
      margin: auto;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      padding: 0;
      text-align: center;
    }
    .profile-badge {
      width: 100%;
      padding-right: 30px;
    }
    .profile-image {
      display: block;
      margin: auto;
    }
}
.home-70 {
    float: right;
    width: 68%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray; 
}

.fade-appear {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
  }

  .fade-enter {
      opacity: 0;
      z-index: 1;
  }

  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opactiy 300ms linear;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 300ms linear;
  }
.project-link {
    text-decoration: none;
}

.project-container {
    color: black;
    margin-bottom: 30px;
    float: right;
    width: 68%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray; 
}

.project-primary-image {
    max-width: 100%;
}

.main-content {
    padding: 10px 0 0 10px;
}

.project-title {
    float: left;
    padding: 6px;
}

.project-date {
    float: right;
    padding: 23px 20px 0 0;
    font-size: 15px;
}

.project-description {
    padding: 20px;
}

.project-technologies {
    padding: 20px 0 10px 20px;
    display: inline-block;
}

.project-github {
    float: right;
}

.img-container {
    max-width: 752px;
}

@media (max-width: 1000px) {
    .project-title {
        width: 100%;
    }

    .project-date {
        float: left;
        padding: 0 0 5px 7px;
    }
}

@media (max-width: 720px) {
    .project-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .project-technologies {
        padding: 20px;
        max-width: 70%;
    }
}


.knowledge-learning-padding {
    padding: 20px 0 10px 20px;
}

.knowledge-div {
    display: inline-block;
    margin: 10px 0;
    padding-left: 20px;
}

.knowledge {
    text-decoration: none;
    color: black;
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 4px;
}

.primary-home-container {
    margin-top: 25px;
}

.home-30 {
    float: left;
    width: 30%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
}

.html {
    border: 1px solid #e44d26;
}

.html:hover {
    background-color: #e44d26;
    transition: 500ms;
}

.css {
    border: 1px solid #3c99dc;
}
.css:hover {
    background-color: #3c99dc;
    transition: 500ms;
}

.javascript {
    border: 1px solid  #f0db4f ;
}

.javascript:hover {
    background-color:#f0db4f ;
    transition: 500ms;
}

.node {
    border: 1px solid #3c873a;
}

.node:hover {
    background: #3c873a;
    transition: 500ms;
}

.express {
    border: 1px solid darkgrey;
}

.express:hover {
    background-color: darkgrey;
    transition: 500ms;
}

.react {
    border: 1px solid #61dbfb;
}

.react:hover {
    background-color: #61dbfb;
    transition: 500ms;
}

.pug {
    border: 1px solid #efcca3;
}

.pug:hover {
    background-color: #efcca3;
    transition: 500ms;
}

.rest {
    border: 1px solid #8cc63f;
}

.rest:hover {
    background-color: #8cc63f;
    transition: 500ms;
}

.sql {
    border: 1px solid #00758F;
}

.sql:hover {
    background-color: #00758F;
    transition: 500ms;
}

.php {
    border: 1px solid #474A8A;
}

.php:hover {
    background-color: #474A8A;
    transition: 500ms;
}

.mongo {
    border: 1px solid #4db33d;
}

.mongo:hover {
    background-color: #4db33d;
    transition: 500ms;
}

.social-icons {
    font-size: 40px;
    margin: 10px 20px;
    color: black;
}

.github:hover {
    color: #7dbbe6;
    transition: 500ms;
}

.linkedin:hover {
    color: #006192;
    transition: 500ms;
}

.instagram:hover {
    color: #dd2a7b;
    transition: 500ms;
}

@media (max-width: 720px) {
    .home-30 {
        width: 100%;
        margin-bottom: 30px;
    }
}
.resume-email,
.resume-location,
.resume-linkedin,
.resume-instagram,
.resume-github {
    display: inline-block;
    padding: 10px 50px;
    font-size: 15px;
}

.contact-info,
.resume-education,
.resume-experience {
    background-color: white;
    border-radius: 6px;
}

.contact-info {
    text-align: center;
    margin: 10px 0;
}

.resume-education {
    width: 30%;
    float: left;
    padding: 15px;
    margin-bottom: 12px;
}

.resume-experience {
    width: 69%;
    float: right;
    padding: 15px;
    margin-bottom: 10px;
}

.resume-education div {
    padding-bottom: 10px;
}

.fade-appear {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
  }

  .fade-enter {
      opacity: 0;
      z-index: 1;
  }

  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opactiy 300ms linear;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 300ms linear;
  }

  .resume-role-extra {
      padding: 10px 0 0 0;
  }

  .resume-role-description {
      padding: 10px 0 0 0;
  }

  .resume-role-link,
  .resume-details {
      text-decoration: none;
      color: black;
  }

  .resume-role-link:hover,
  .resume-details:hover {
      color: #9fcaac;
  }

  .resume-duty-ul {
      padding: 10px 0;
  }

  .resume-duty {
      margin-left: 30px;
      font-size: .9em;
      padding: 3px 0;
  }

  .resume-education-location,
  .resume-certification-location {
      padding: 5px 0;
  }
  .resume-education-degree {
      padding: 0 0 5px 0;
  }

@media (max-width: 1000px) {
    .resume-education {
        width: 100%;
        text-align: center;
    }

    .resume-education-container {
        display: inline-block;
        padding: 20px 30px 20px 20px;
    }

    .project-date {
        float: left;
        padding: 0 0 5px 7px;
    }
}

@media (max-width: 720px) {
    .resume-experience {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .resume-email,
    .resume-location,
    .resume-linkedin,
    .resume-instagram,
    .resume-github {
        width: 100%;
    }
}

/* @media (max-width: 1000px) {
    .project-title {
        width: 100%;
    }

    .project-date {
        float: left;
        padding: 0 0 5px 7px;
    }
}

@media (max-width: 720px) {
    .project-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .project-technologies {
        padding: 20px;
        max-width: 70%;
    }
} */
.main-footer {
    background-color: white;
    border-radius: 6px 6px 0 0;
    height: 50px;
    text-align: center;
    padding-top: 18px;
    margin-top: 10px;
}
textarea,
input {
    outline: none;
}

textarea:focus,
input:focus {
    outline: 1px solid #9fcaac;
}

.fade-appear {
    opacity: 0;
    z-index: 1;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opactiy 300ms linear;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear;
}

.contact-main-container {
    margin: 23px 0 0 0;
}

.contact-form-container {
    float: right;
    width: 68%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray; 
    padding-left: 20px;
}

.contact-details-container {
    float: left;
    width: 30%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
}

.contact-form-general {
    display: inline-block;
    border: none;
    min-width: 400px;
    border-radius: 4px;
    box-shadow: .5px .5px 5px lightslategray; 
    margin: 10px;
    padding: 10px;
}

.contact-form-general:focus {
    border: none;
}


.contact-form-name,
.contact-form-email,
.contact-form-phone {
    height: 40px;
}

.contact-form-email,
.contact-form-phone {
    width: 190px;
}

.contact-form-message {
    min-height: 200px;
}

.contact-form-submit {
    display: block;
    background-color: white;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 4px;
    box-shadow: .5px .5px 5px lightslategray; 
}


.contact-form-error {
    margin-left: 30px;
}

.contact-form-text {
    width: 400px;
    padding: 10px;
}

@media (max-width: 720px) {
    .contact-details-container {
        width: 100%;
    }

    .contact-form-container {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px
    }
}

@media (max-width: 495px) {
    .contact-form-email,
    .contact-form-name,
    .contact-form-text,
    .contact-form-phone {
        max-width: 250px;
    }
}
.blog-tile-container {
    display: inline-block;
    height: 520px;
    width: 355px;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
    margin: 20px 20px 0 0;
}

.blog-tile-container:nth-child(3n+3) {
    margin: 20px 0 0 0;
}

.blog-tile-image {
    width: 355px;
    height: 200px;
    border-radius: 6px 6px 0 0;
}

.blog-tile-title {
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.blog-tile-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.blog-tile-read-more-link {
    text-decoration: none;
}

.blog-tile-read-more-text {
    display: inline;
    font-weight: 100;
    font-size: large;
    margin-left: 35px;
    color: black;
    padding: 5px;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;

}

.blog-tile-technologies {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.blog-tile-read-more-text:active {
    box-shadow: .5px .5px 3px #797979;
    background-color: #fafafa;
}
