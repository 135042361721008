.knowledge-learning-padding {
    padding: 20px 0 10px 20px;
}

.knowledge-div {
    display: inline-block;
    margin: 10px 0;
    padding-left: 20px;
}

.knowledge {
    text-decoration: none;
    color: black;
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 4px;
}

.primary-home-container {
    margin-top: 25px;
}

.home-30 {
    float: left;
    width: 30%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
}

.html {
    border: 1px solid #e44d26;
}

.html:hover {
    background-color: #e44d26;
    transition: 500ms;
}

.css {
    border: 1px solid #3c99dc;
}
.css:hover {
    background-color: #3c99dc;
    transition: 500ms;
}

.javascript {
    border: 1px solid  #f0db4f ;
}

.javascript:hover {
    background-color:#f0db4f ;
    transition: 500ms;
}

.node {
    border: 1px solid #3c873a;
}

.node:hover {
    background: #3c873a;
    transition: 500ms;
}

.express {
    border: 1px solid darkgrey;
}

.express:hover {
    background-color: darkgrey;
    transition: 500ms;
}

.react {
    border: 1px solid #61dbfb;
}

.react:hover {
    background-color: #61dbfb;
    transition: 500ms;
}

.pug {
    border: 1px solid #efcca3;
}

.pug:hover {
    background-color: #efcca3;
    transition: 500ms;
}

.rest {
    border: 1px solid #8cc63f;
}

.rest:hover {
    background-color: #8cc63f;
    transition: 500ms;
}

.sql {
    border: 1px solid #00758F;
}

.sql:hover {
    background-color: #00758F;
    transition: 500ms;
}

.php {
    border: 1px solid #474A8A;
}

.php:hover {
    background-color: #474A8A;
    transition: 500ms;
}

.mongo {
    border: 1px solid #4db33d;
}

.mongo:hover {
    background-color: #4db33d;
    transition: 500ms;
}

.social-icons {
    font-size: 40px;
    margin: 10px 20px;
    color: black;
}

.github:hover {
    color: #7dbbe6;
    transition: 500ms;
}

.linkedin:hover {
    color: #006192;
    transition: 500ms;
}

.instagram:hover {
    color: #dd2a7b;
    transition: 500ms;
}

@media (max-width: 720px) {
    .home-30 {
        width: 100%;
        margin-bottom: 30px;
    }
}