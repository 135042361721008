.resume-email,
.resume-location,
.resume-linkedin,
.resume-instagram,
.resume-github {
    display: inline-block;
    padding: 10px 50px;
    font-size: 15px;
}

.contact-info,
.resume-education,
.resume-experience {
    background-color: white;
    border-radius: 6px;
}

.contact-info {
    text-align: center;
    margin: 10px 0;
}

.resume-education {
    width: 30%;
    float: left;
    padding: 15px;
    margin-bottom: 12px;
}

.resume-experience {
    width: 69%;
    float: right;
    padding: 15px;
    margin-bottom: 10px;
}

.resume-education div {
    padding-bottom: 10px;
}

.fade-appear {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
  }

  .fade-enter {
      opacity: 0;
      z-index: 1;
  }

  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opactiy 300ms linear;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 300ms linear;
  }

  .resume-role-extra {
      padding: 10px 0 0 0;
  }

  .resume-role-description {
      padding: 10px 0 0 0;
  }

  .resume-role-link,
  .resume-details {
      text-decoration: none;
      color: black;
  }

  .resume-role-link:hover,
  .resume-details:hover {
      color: #9fcaac;
  }

  .resume-duty-ul {
      padding: 10px 0;
  }

  .resume-duty {
      margin-left: 30px;
      font-size: .9em;
      padding: 3px 0;
  }

  .resume-education-location,
  .resume-certification-location {
      padding: 5px 0;
  }
  .resume-education-degree {
      padding: 0 0 5px 0;
  }

@media (max-width: 1000px) {
    .resume-education {
        width: 100%;
        text-align: center;
    }

    .resume-education-container {
        display: inline-block;
        padding: 20px 30px 20px 20px;
    }

    .project-date {
        float: left;
        padding: 0 0 5px 7px;
    }
}

@media (max-width: 720px) {
    .resume-experience {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .resume-email,
    .resume-location,
    .resume-linkedin,
    .resume-instagram,
    .resume-github {
        width: 100%;
    }
}

/* @media (max-width: 1000px) {
    .project-title {
        width: 100%;
    }

    .project-date {
        float: left;
        padding: 0 0 5px 7px;
    }
}

@media (max-width: 720px) {
    .project-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .project-technologies {
        padding: 20px;
        max-width: 70%;
    }
} */