h4 {
    font-size: 25px;
  }
  
  .name,
  .position {
    padding-bottom: 4px;
  }
  
  .main-header {
    background-color: white;
    height: 175px;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
  }
  
  .primary-container {
    max-width: 1145px;
    margin: auto;
    height: inherit;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .profile-badge {
    padding: 24px 0 0 30px;
    display: inline-block;
  }
  
  .profile-image-container {
    float: left;
  }
  
  .profile-content {
    width: 400px;
    display: inline-block;
    padding-left: 30px;
    padding-top: 18px;
  }
  
  .profile-image {
    max-width: 125px;
  }
  
  .main-navigation {
    float: right;
    padding-right: 55px;
    padding-top: 55px;
  }
  
  .to-inline {
    display: inline;
  }


  @media (max-width: 768px) {
    .main-navigation {
      position: fixed;
      bottom: 30px;
      right: 30px;
      padding-right: 0;
      padding-top: 0;
    }
  }

  @media (max-width: 720px) {
    .main-header {
      display: block;
      height: 260px;
      margin: auto;
    }
    .profile-image-container {
      display: block;
      float: none;
      margin: auto;
      max-width: 100%;
      justify-content: center;
    }
    .profile-content {
      display: block;
      float: none;
      margin: auto;
      width: max-content;
      padding: 0;
      text-align: center;
    }
    .profile-badge {
      width: 100%;
      padding-right: 30px;
    }
    .profile-image {
      display: block;
      margin: auto;
    }
}