@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans';
}

body {
  background-color: rgb(201, 201, 201);
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}