.blog-tile-container {
    display: inline-block;
    height: 520px;
    width: 355px;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
    margin: 20px 20px 0 0;
}

.blog-tile-container:nth-child(3n+3) {
    margin: 20px 0 0 0;
}

.blog-tile-image {
    width: 355px;
    height: 200px;
    border-radius: 6px 6px 0 0;
}

.blog-tile-title {
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.blog-tile-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.blog-tile-read-more-link {
    text-decoration: none;
}

.blog-tile-read-more-text {
    display: inline;
    font-weight: 100;
    font-size: large;
    margin-left: 35px;
    color: black;
    padding: 5px;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;

}

.blog-tile-technologies {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.blog-tile-read-more-text:active {
    box-shadow: .5px .5px 3px #797979;
    background-color: #fafafa;
}