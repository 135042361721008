.project-link {
    text-decoration: none;
}

.project-container {
    color: black;
    margin-bottom: 30px;
    float: right;
    width: 68%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray; 
}

.project-primary-image {
    max-width: 100%;
}

.main-content {
    padding: 10px 0 0 10px;
}

.project-title {
    float: left;
    padding: 6px;
}

.project-date {
    float: right;
    padding: 23px 20px 0 0;
    font-size: 15px;
}

.project-description {
    padding: 20px;
}

.project-technologies {
    padding: 20px 0 10px 20px;
    display: inline-block;
}

.project-github {
    float: right;
}

.img-container {
    max-width: 752px;
}

@media (max-width: 1000px) {
    .project-title {
        width: 100%;
    }

    .project-date {
        float: left;
        padding: 0 0 5px 7px;
    }
}

@media (max-width: 720px) {
    .project-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .project-technologies {
        padding: 20px;
        max-width: 70%;
    }
}

