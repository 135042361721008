span {
    margin-left: 16px 18px 0px 18px;
}
.navbar {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    margin-top: 4px;
    padding-top: 9px;
    align-items: center;
    /* border: 1px solid black; */
    border-radius: 50%;
}

.menu-bars {
    margin: 45px 20px;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #fff;
    width: 130px;
    height: 275px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -90%;
    transition: 1500ms;
    transition-delay: 0ms;
    border-radius: 6px 6px 6px 6px;
    box-shadow: .5px .5px 5px lightslategray;
    margin-top: 30px;
    overflow: hidden;
}

.nav-menu.active {
    right: 35px;
    transition: 500ms;
}

.nav-text {
    display: flex;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #75be9a;
    transition: 500ms;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 20px;

}

.toggler {
    padding: 12px;
    background-color: white;
    border-radius: 50%;
    padding: 10px 12px 2px 12px;
    box-shadow: .5px .5px 5px rgb(121, 121, 121);
}

.toggler:active {
    box-shadow: .5px .5px 3px #797979;
    background-color: #fafafa;
}

.close-icon {
    font-size: 20px;
}


@media (max-width: 768px) {
    .toggler {
        background-color: white;
        border-radius: 50%;
        padding: 10px 12px 2px 12px;
        box-shadow: .5px .5px 5px rgb(121, 121, 121);
    }

    .navbar {
        border: none;
    }
  }