.home-70 {
    float: right;
    width: 68%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray; 
}

.fade-appear {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
  }

  .fade-enter {
      opacity: 0;
      z-index: 1;
  }

  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opactiy 300ms linear;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 300ms linear;
  }