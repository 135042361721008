textarea,
input {
    outline: none;
}

textarea:focus,
input:focus {
    outline: 1px solid #9fcaac;
}

.fade-appear {
    opacity: 0;
    z-index: 1;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opactiy 300ms linear;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear;
}

.contact-main-container {
    margin: 23px 0 0 0;
}

.contact-form-container {
    float: right;
    width: 68%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray; 
    padding-left: 20px;
}

.contact-details-container {
    float: left;
    width: 30%;
    background-color: white;
    border-radius: 6px;
    box-shadow: .5px .5px 5px lightslategray;
}

.contact-form-general {
    display: inline-block;
    border: none;
    min-width: 400px;
    border-radius: 4px;
    box-shadow: .5px .5px 5px lightslategray; 
    margin: 10px;
    padding: 10px;
}

.contact-form-general:focus {
    border: none;
}


.contact-form-name,
.contact-form-email,
.contact-form-phone {
    height: 40px;
}

.contact-form-email,
.contact-form-phone {
    width: 190px;
}

.contact-form-message {
    min-height: 200px;
}

.contact-form-submit {
    display: block;
    background-color: white;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 4px;
    box-shadow: .5px .5px 5px lightslategray; 
}


.contact-form-error {
    margin-left: 30px;
}

.contact-form-text {
    width: 400px;
    padding: 10px;
}

@media (max-width: 720px) {
    .contact-details-container {
        width: 100%;
    }

    .contact-form-container {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px
    }
}

@media (max-width: 495px) {
    .contact-form-email,
    .contact-form-name,
    .contact-form-text,
    .contact-form-phone {
        max-width: 250px;
    }
}